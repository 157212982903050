import styles from "./web-component-article.styles.scss";
import webComponentArticleHtml from "./web-component-article.html";

export class WebComponentArticle extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const style = document.createElement("style");
    style.textContent = styles;
    const element = document.createElement("div");
    element.innerHTML = webComponentArticleHtml;
    const shadow = this.attachShadow({ mode: "open" });
    shadow.appendChild(style.cloneNode(true));
    shadow.appendChild(element.cloneNode(true));
  }
}
