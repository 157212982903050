import "./app.scss";
import { FlipCard } from "./components/flip-card/flip-card";
import { initializeApp } from "firebase/app";
import { HeaderNav } from "./components/header/header-nav";
import { WebComponentArticle } from "./articles/web-component/web-component-article";

const firebaseConfig = {
  apiKey: "AIzaSyBfxOAux58Xw4FjcCWpxcGmygzJcpspy7Y",
  authDomain: "leafar-fd10b.firebaseapp.com",
  projectId: "leafar-fd10b",
  storageBucket: "leafar-fd10b.appspot.com",
  messagingSenderId: "639740376207",
  appId: "1:639740376207:web:2a8d52dedcea26be81ecfa",
  measurementId: "G-1DLLQB7MKW",
};

initializeApp(firebaseConfig);

customElements.define("flip-card", FlipCard);
customElements.define("header-nav", HeaderNav);
customElements.define("web-component-article", WebComponentArticle);
